import React, { Component } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { buildBlocks } from "../services/builder";
import { CoreFields } from "./fragment"; // eslint-disable-line

class Post extends Component {
  render() {
    this.props.pageContext.pageTitle = this.props.data.wpPost.Meta.title
      ? this.props.data.wpPost.Meta.title
      : this.props.data.wpPost.title;
    this.props.pageContext.date = this.props.data.wpPost.date;
    this.props.pageContext.author =
      this.props.data.wpPost.author.node.firstName +
      " " +
      this.props.data.wpPost.author.node.lastName;
    const blocks = buildBlocks(
      this.props.data.wpPost.blocks,
      {},
      this.props.pageContext
    );

    return (
      <Layout
        meta={this.props.data.wpPost.Meta}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wpPost.PageCTA}
        title={this.props.data.wpPost.title}
        themeOptions={this.props.data.wp.themeOptions}
        globalAlert={this.props.data.wp.themeOptions.ThemeOptions.globalAlert}
      >
        {blocks}
      </Layout>
    );
  }
}

export const query = graphql`
  query($id: String!) {
    wpPost(id: {eq: $id}) {
        id
        slug
        title
        date
        author {
          node {
            lastName
            firstName
          }
        }
        Meta {
          fieldGroupName
          metaDescription
          metaTitle
          ogDescription
          ogTitle
          twitterCardType
          twitterDescription
          twitterTitle
          twitterImage {
            uri
            sourceUrl
          }
          ogImage {
            uri
            sourceUrl
          }
        }
        PageCTA {
          actionType
          ctaText
          fieldGroupName
          link {
            url
          }
        }
        ... on WpBlockEditorContentNode {
          blocks {
            ...CoreBlock
            ...CoreFields
          }
        }
      }
      wp {
        themeOptions {
          accommodationActive
          roomBookingCTAText
          roomBookingImage
          roomBookingImageText
          roomBookingLink
          tableBookingAccountId
          tableBookingActive
          tableBookingBrandId
          tableBookingCTAText
          tableBookingImage
          tableBookingImageText
          tableBookingRestaurantId
          ThemeOptions {
            siteLogo {
              mediaItemUrl
            }
          }
        }
      }
    }
`;

export default Post;
